.question_search{
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 0.8rem;
    color: $charcoal;
}

.question_search_input_holder{
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: $margin-small;
}

.search_actions{
    position: absolute;
    height: 100%;
    display: flex;
    right: 0;
    .search_actions__action{
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        background: transparent;
        border: none !important;
        outline: none !important;
    }
}