.kb_page_top_bar{
    position: relative;
    display: flex;
    background: white;
    width: 100%;
    padding: $padding-medium;
    border-bottom: 1px solid $border-color;
    align-items: center;

    @media screen and (max-width: $mobile-breakpoint) {
        display: block;

        .question_search{
            justify-content: flex-end;
            
            .question_search_input_holder{
                margin-right: 0px;
            }
        }

        .kb_page_top_bar__info{
            margin-bottom: $margin-medium;
        }

        .kb_page_top_bar__actions{
            justify-content: space-between;
        }

        .kb_page_top_bar__action{
            font-size: 0.59rem;
            flex-grow: 1;
            margin-right: 0.2rem !important;
        }
    }
}

.kb_page_top_bar__title{
    font-weight: bold;
    margin-right: $margin-medium;
}

.kb_page_top_bar__actions{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.kb_page_top_bar__action{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 0.5rem;
    border: 1px solid $primary-color;
    padding: 0.4rem 1rem;
    border-radius: $border-radius-small;
    color: $primary-color;
    font-size: 0.8rem;
    transition: background 0.2s;
    font-weight: 400;

    &.main{
        background: $primary-color;
        color: white;
        font-weight: 500;
    }

    svg{
        margin-right: 0.3rem;
    }

    &:last-child{
        margin-right: 0;
    }

    &:hover{
        background: darken($primary-color, 10%);
        color: white;
    }
}

.kb_page_top_bar__info{
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.kb_chat__title__actions{
    display: flex;
}