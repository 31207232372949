.kb_page_container{
    display: flex;
    flex-grow: 1;
}

.kb_page{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100vw;
}

.kb_page__panel{
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.select_all{
    margin-bottom: $margin-medium;
}

.ReactModalPortal .ReactModal__Overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    background: rgba(black, 0.5) !important;
}

.modal{
    background: white;
    align-self: center;
    padding: 3rem 2rem;
    border-radius: $border-radius-small;
    width: 600px;
    outline: none !important;
    margin: 0px 1rem;
}

.modal__title{
    font-weight: bold;
    font-size: 1.8rem;
    padding: 0.5rem 0px 1rem 0px;
}

.modal__content{
    color: #888;
    margin-bottom: 1.5rem;
}

.modal__actions{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $border-color;
    padding-top: 0.5rem;
    button{
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.audit_trail_widget{
    @extend .kb_chat;
    width: 300px !important;
    .kb_chat__title{
        padding: 0px 0px 0px $margin-medium;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100% !important;
    }
}

.audit_trail_widget__innner{
    overflow-y: auto;
}

.no_items{
    padding: $padding-medium;
    font-size: 0.8rem;
    color: #777;
}

.audit_trail_item{
    padding: 0.8rem 0px;
    margin: 0px $margin-medium;
    border-bottom: 1px solid $border-color-light;
    animation: fadeIn 0.5s ease;
    &.save, &.notify, &.publish{
        padding: $padding-medium;
        border: 1px solid $primary-color;
        margin-top: $margin-medium;
        border-radius: $border-radius-small;

        .audit_trail__badge{
            background: $primary-color;
            color: white;
            
        }
        
        .audit_trail__label{
            font-weight: 500;
            color: $charcoal;
            font-style: normal;
        }
    }
}

.audit_trail_link{
    font-size: 0.75rem;
    font-weight: 400;
    color: #777;
    cursor: pointer;

    &:hover{
        color: $primary-color;
        text-decoration: underline;
    }
}

.audit_trail__badge{
    position: relative;
    display: block;
    width: fit-content;
    font-size: 0.6rem;
    padding: 0.2rem 0.6rem;
    background: lighten($online-color, 30%);
    border-radius: $border-radius-small;
    color: rgba(black, 0.5);
    font-weight: 500;
    margin-bottom: 0.2rem;
    &.delete{ background: lighten($warning-red, 20%);}

    &.edit{ background: lighten($charcoal, 75%);}
}

.audit_trail__label{
    color: #777;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
    font-style: italic;
}