@keyframes fadeIn{
    from{opacity: 0;}
    to{opacity: 1;}
}



@keyframes placeHolderShimmer {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 10000px 0
    }
}