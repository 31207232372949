.kb_chat{
    display: flex;
    flex-direction: column;
    width: 500px;
    background: white;
    border-left: 1px solid $border-color;    
}

.widget_expanded{
    @media screen and (max-width: $mobile-breakpoint) {
        display: none !important;
    }
}

.kb_chat__title{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    border-bottom: 1px solid $border-color;
    align-items: center;
}

.kb_chat__tags_applied{
    border-bottom: 1px solid $border-color;
    padding-bottom: $padding-medium;
    label{
        padding: $padding-medium;
        padding-bottom: 0px;
    }

    .question_tags__inner{
        padding: 0px $padding-medium;
        margin: 0px;
        flex-wrap: wrap;
    }

    .tag{
        white-space: nowrap;
        margin-bottom: $padding-small;
    }
}

.close_chat{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
        background: darken(white, 3%);
    }
}

.kb_chat_messages{
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;
}

.kb_chat__message_bar{
    display: flex;
    position: relative;
    justify-content: space-between;

    input{
        flex-grow: 1;
        border: none;
        border-top: 1px solid $border-color;
        padding: 0px 1rem;

        &:focus{
            outline: none;
    
        }
    }

    button{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: $primary-color;
        border-radius: 0px;
        color: white;
        cursor: pointer;
        transition: all 0.3s;

        &:hover{
            background: darken($primary-color, 10%);
        }
    }
}

.kb_message__buble{
    @extend .message__buble;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    margin-left: 0rem;
    margin-right: 0.5rem;
    flex-direction: row;
    .message-preloader{
        display: flex;
    }

    .message__text{
        word-break: keep-all;
    }

}

.kb_chat__message{
    margin-bottom: 1rem;
    align-items: baseline;

    &.user{
        flex-direction: row-reverse;
        justify-content: right;

        .kb_message__buble{
            margin-left: 0.5rem;
            margin-right: 0px;
            background: $primary-color;
            color: white;
        }
    }
}

.message_media_item__outer{
    display: flex;
    justify-content: center;
}

.message__prompts{
    padding: 1rem;
    background: $offwhite;
}

.message_prompt{
    padding: 0.5rem;
    text-align: center;
    background: white;
    margin-bottom: 0.5rem;
    border-radius: $border-radius-small;
    border: 1px solid $border-color;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;
    word-break: keep-all;
    transition: background 0.2s;
    &:last-child{
        margin-bottom: 0px;
    }

    &:hover{
        background: $primary-color;
        color: white;
    }
}

.kb_chat__start{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 22;
    border-top: 1px solid $border-color;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: white;
    background: $primary-color;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
        background: darken($primary-color, 5%);
    }
}