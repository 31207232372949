.dashboard_topbar{
    position: relative;
    display: flex;
    z-index: 49;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    align-items: center;
    justify-content: space-between;
    padding: 0 $margin-medium;
    min-height: 50px;
}