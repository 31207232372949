
.chat_channel_icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 0 2.5px 5px rgba(0,0,0,.1);

    img{
        position: absolute;
        width: 65%;
        height: 65%;
        display: block;
    }
}