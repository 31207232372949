$accept-color: #25d366;
$transfer-color: #ebebeb;
$decline-color: #ff4747;

.human_handoff_notification{
    position: relative;
    display: flex;
    padding: $padding-medium;
    flex-direction: column;

    .queue_badge{
        margin: 0px;
        margin-bottom: 0.2rem;
    }
}

.human_handoff_notification__info{
    display: flex;
    align-items: center;
    margin: 1.5rem 0px;
}

.human_handoff_notification__title{
    position: relative;
    display: block;
    color: $grey;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
}

.human_handoff_notification__info__user{
    position: relative;
    display: block;
    margin-left: $margin-small;
    max-width: 80%;
}

.human_handoff_notification__info__user__name{
    font-weight: 500;
}

.human_handoff_actions{
    display: flex;
    justify-content: space-evenly;
}

.human_handoff_action{
    flex-grow: 1;
    color: white;
    text-align: center;
    padding: 0.5rem 0px;
    font-size: 0.7rem;
    font-weight: 500;
    margin-right: 0.3rem;
    border-radius: $border-radius-small;
    transition: all 0.3s;
    cursor: pointer;

    &:last-child{
        margin-right: 0;
    }

    &.accept{
        background: $accept-color;
        &:hover{
            background: darken($accept-color, 5%);
        }
    }

    &.transfer{
        background: $transfer-color;
        color: $charcoal;
        &:hover{
            background: darken($transfer-color, 5%);
        }
    }

    &.decline{background: $decline-color;
        &:hover{
            background: darken($decline-color, 5%);
        }
    }
}

.human_handoff_notification__overlay{
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    z-index: 2;
}