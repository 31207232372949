// Colors
$user-color: radial-gradient(circle, rgba(85,85,85,1) 0%, rgba(54,54,54,1) 100%);
$bot-color: $primary-color;

.chat_message_avatar{
    position: relative;
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 38px;
    height: 38px;
    margin: 0 8px;
    margin-bottom: 1.4rem;
    border-radius: 50%;
    overflow: hidden;
    cursor: default;

    &.bot{
        background: $bot-color;
    }

    &.user{
        background: $user-color;
    }

    img{
        max-width: 50%;
        max-height: 50%;
    }
}

.chat_message_avatar__initials{
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: .8rem;
    line-height: 0.8rem;
    letter-spacing: 1px;
}