.chat_message_bar{
    position: relative;
    display: flex;
    background: white;
    border-top: 1px solid $border-color-light;
    padding: $padding-medium;
    .button{
        width: 100px;
        margin-left: 0.5rem;
    }

    input[type="text"]{
        border-radius: 1.5rem;
        outline: none;
        padding: 0 1rem;
        line-height: 35px;
        border: 1px solid rgba(0,0,0,.1);
    }
}