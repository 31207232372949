form{
    display: block;
}

.form-group__split{
    display: flex;
    justify-content: space-between;
    .form-group{
        width: calc(50% - 0.5rem);
    }

    @media (max-width: $mobile-breakpoint){
        display: block;
        .form-group{
            width: 100%;
        }
    }
}

.form-group{
    position: relative;
    width: 100%;
    margin-bottom: $margin-medium;

    .input-field{
        display: block;
    }
}

label{
    font-size: 0.8rem;
    color: $grey;    
    margin-bottom: $margin-small;
    display: block;
}

.input-field{
    outline: none;
    width: 100%;
    line-height: 1.5;
    padding: .4rem .75rem;
    border: 1px solid $light-grey;
    border-radius: .25rem;
    resize: none;
    font-family: inherit;
}

textarea, input[type="email"], input[type="password"], input[type="text"], input[type="number"]{
    @extend .input-field;
}

.help-block{
    font-size: 0.8rem;
    color: $warning-red;
}