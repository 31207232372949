p{
    font-size: 0.8rem;
}

.text-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hero_title{
    font-weight: bold;
    font-size: 1.8rem;
    padding: 3rem 0 1rem 0px;
    border-bottom: 2px solid $border-color;
    margin-bottom: 1rem;
}