.question_list__panel{
    display: flex;
    justify-content: center;
    flex-grow: 1;
    overflow-y: auto;
}

.question_list{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.question_item, .question_list_legend{
    position: relative;
    display: flex;
    background: white;
    border-radius: $border-radius-medium;
    margin-bottom: 1rem;

    &:last-child{
        margin-bottom: 2rem;
    }
}

.question_search__input{
    padding-right: 17% !important;
}

.question_list_legend{
    font-size: 1rem;
    font-weight: bold;
    align-items: center;
    color: $primary-color;
}

.question_item__questions_rephrasing{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color-light;
    padding: 0.5rem 0px;
    font-style: italic;
    color: $grey;
    word-break: keep-all;

    &:last-child{
        border-bottom: none;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.question_item__answer{
    position: relative;
    margin-bottom: 1rem;
    white-space: pre-line;
    cursor: text;

    p{
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    
    textarea, input{
        margin: $margin-small;
        margin-top: 0rem;
        margin-bottom: 0px !important;
    }

    div{    
        position: relative;
        white-space: pre-line;
        word-break: break-word;
    }
}

.question_item__answer__inner{
    display: flex;
    align-items: center;
}

.emoji-picker-react{
    position: absolute !important;
    right: 0;
}

.answer_action{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #777;
    font-size: 1.2rem;
}

.question_item{
    textarea, input{
        margin-top: 0rem;
        margin-bottom: $margin-medium;
    }

    .preloader{
        align-items: start;
        padding: 0px !important;
        margin-bottom: $margin-medium;
    }

    .question_item__answer__actions{
        margin-bottom: $margin-medium;
    }

    .question_item__answer__action{
        width: fit-content;
        font-size: 1.4rem;
        cursor: pointer;
    }
    
    @media screen and (max-width: $mobile-breakpoint) {
        .question_iten__action{
            display: none;
        }
    }
}

.menu_option_field{
    border: none !important;
    cursor: text !important;
    margin-bottom: 0.8rem !important;

    &:hover{
        background: $offwhite !important;
    }
}

.question_item__menu_items{
    margin-bottom: 0.8rem;

    &[disabled]::after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(white, 0.5);
        top: 0;
        left: 0;
        cursor: not-allowed;
    }
}

.question_iten__left{
    width: 30%;
    border-right: 1px solid $border-color-light;
    padding: 1rem;
    flex-shrink: 0;

    @media screen and (max-width: $mobile-breakpoint) {
        width: 50%;
    }
}

.question_item__questions_rephrasing__text{
    flex-grow: 1;
}

.question_iten__right{
    padding: 1rem;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.answer_heading{
    display: flex;

    @media screen and (max-width: $mobile-breakpoint) {
        .kb_page_top_bar__action{
            span{
                display: none;
            }

            padding: 0.5rem;
            
            svg{
                margin-right: 0px;
            }
        }
    }
}

.question_list__action{
    border: 1px solid $primary-color;
    font-weight: 500;
    padding: 0.5rem 0.8rem;
    border-radius: $border-radius-small;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;
    cursor: pointer;

    &:hover{
        background: $primary-color;
        color: white;
    }
}

.remove_rephrasing{
    cursor: pointer;
    margin-left: 0.5rem;
}

.question_actions{
    display: flex;
    justify-content: flex-end;

    .button{
        margin-right: 0.3rem;
        &:last-child{
            margin-right: 0px;
        }
    }
}

.question_item__menu_items__item{
    display: flex;
    flex-direction: column;
    background: $offwhite;
    width: fit-content;
    padding: 0.3rem 0.6rem;
    margin-bottom: 0.8rem;
    font-size: 0.8rem;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius-small;
    cursor: pointer;
    transition: background-color 0.3s;

    &:last-child{
        margin-bottom: 0px;
    }

    &:hover{
        background-color: darken($offwhite, 3%);
    }
}

.question_item__menu_items__label{
    position: relative;
    display: flex;
    align-items: center;

    svg{
        margin-right: 0.3rem;
    }
}

.question_item__action_button{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    width: fit-content;
    padding: 0.3rem 0.6rem;
    background-color: $offwhite;
    border-radius: $border-radius-small;
    cursor: pointer;
    transition: all 0.2s;

    svg{
        margin-right: 0.3rem;
        font-size: 0.9rem;
        line-height: 0.9rem;
    }

    &:hover{
        background-color: darken($offwhite, 5%);
    }
}

.question_iten__action{
    padding: 1rem;
    cursor: pointer;
    color: #777;
    height: fit-content;

    &.cg{
        font-size: 1.5rem !important;
        padding: 0.675rem;
    }
}

.question_tags{
    display: flex;
    align-items: center;
    margin-bottom: $margin-medium;
    font-size: 0.8rem;
    color: $grey;
    font-weight: bold;
}

.question_tags__inner{
    display: flex;
    margin-left: $margin-small;
}

.tag{
    padding: $padding-small 0.5rem;
    font-weight: 500;
    margin-right: $margin-small;
    background: lighten($online-color, 20%);
    color: darken($online-color, 20%);;
    font-size: .65rem;
    cursor: default;
    border-radius: $border-radius-small;
    height: fit-content;
    text-align: center;
}

.question_item__menu_items__item .question_tags{
    margin-top: $margin-small;
    margin-bottom: 0px;

    .question_tags__inner{
        margin-left: 0px;
        display: flex;
        align-items: center;
    }
}

.quill{
    width: 100%;
    margin: 0px $margin-medium;
}

.select{
    margin-bottom: $margin-medium;
}

.question_legend_actions{
    display: flex;
    align-items: center;
}
