.not_found_page{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: $padding-medium;
}

.not_found_bold{
    font-weight: 700;
    font-size: 4rem;
    color: $primary-color;
}

.not_found_heading{
    font-size: 2.5rem;
    margin-bottom: $margin-medium;
}

.not_found_text{
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: $margin-large;
    max-width: 600px;
    text-align: center;
}

.not_found_button{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem $margin-medium;
    border: 1px solid $border-color;
    background: $primary-color;
    color: white !important;
    text-decoration: none;
    border-radius: $border-radius-small;

    svg{
        margin-right: $margin-small;
    }

    &:hover{
        background: darken($primary-color, 10%);
    }
}