.status{
    margin-bottom: $margin-medium;
}

.status_display{
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    color: $grey;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    span{
        vertical-align: middle;
    }

    &.Available .status_icon{ color: $online-color; }
    &.Busy .status_icon{ color: $warning-red; }
    &.Offline .status_icon{ color: $light-grey; }
}

.status_icon{
    font-size: 1.2rem;
    vertical-align: middle;
    margin-right: 0.3rem;
}

.status_selector{
    position: relative;
    max-width: 300px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .chevron{
        margin-left: $margin-small;
        color: $grey;
    }

    .status_selector__item{
        margin: -$padding-medium;
        min-width: 150px;
        transition:  all 0.3s;
        margin-bottom: $padding-medium;
        padding: 0.75rem $padding-medium;
        &:last-child{
            margin-bottom: -$padding-medium;
        }

        &:hover{
            background: $offwhite;
        }
    }
}