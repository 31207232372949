* {
    box-sizing: border-box;
}

body{
    background-color: $offwhite;
}

a{
    color: $grey;
    transition: all 0.3s;

    &:hover{
        color: $primary-color;
    }
}

::-webkit-scrollbar {
    width: .3em;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.1);
    border-radius: 1rem;
}
::-webkit-scrollbar-track {
    background: #eceff1;
}

.count_badge{
    position: absolute;
    display: flex;
    bottom: -15%;
    left: -15%;
    background-color: $warning-red;
    color: white;
    height: 0.8rem;
    width: 0.8rem;
    line-height: 0.8rem;
    border-radius: 50%;
    font-size: 0.5rem;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.timestamp{
    color: #888;
    font-size: 0.7rem;
}

.back{
    font-size: 1.3rem;
    line-height: 1.3rem;
    width: 2rem;
    height: 1.3rem;
    text-align: center;
    margin-right: 1rem;
}

.container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    &.container_boxed{
        max-width: 1200px;
        margin: 0px auto;
        padding: 1rem 1.5rem;
    }

    &.column{
        flex-direction: column;
    }

    @media(max-width: $mobile-breakpoint){
        flex-direction: column;
    }
}

.col-3{
    width: 33%;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-6{
    width: 50%;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-8{
    width: 66%;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}

.col-12{
    width: 100%;
    @media(max-width: $mobile-breakpoint){
        width: 100%;
    }
}