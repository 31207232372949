.user_info_list_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color-light;
    padding: 0.5rem 0px;
    word-break: break-all;

    input{
        height: 25px;
        margin: 0px;
        margin-left: 1rem;
    }

    &:last-child{
        border: none;
        padding-bottom: 0px;
    }
}

.user_info_list_item__details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.user_info_list_item__key{
    font-size: 0.75rem;
    color: #777;
}

.user_info_list_item__value{
    font-size: 0.75rem;
    max-width: 70%;
}

.user_info_list_item__actions{
    display: flex;
}

.user_info_list_item__action{
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    margin-left: 0.3rem;

    &.save{
        color: $online-color;
    }

    &.cancel{
        color: $warning-red;
    }

    &:first-child{
        margin-left: 0.8rem;
    }
}