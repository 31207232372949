.chats_list{
    position: relative;
    display: block;
    max-width: 300px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    overflow-y: auto;
    border-right: 1px solid $border-color-light;
}

.chat_list__item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding: .8rem 1rem;
    background: #fff;
    transition: all .3s;
    font-size: 14px;
    border-bottom: 1px solid #f5f6f5;
    cursor: pointer;
    line-height: 1.5;
    
    &:hover{
        background-color: $offwhite;
    }

    &.active{
        background-color: $offwhite;
    }
}

.chat_list__item_details{
    position: relative;
    display: block;
    width: 75%;
    margin-left: 5%;
}

.chat_list__item__title{
    position: relative;
    display: block;
    font-size: .8rem;
    font-weight: 500;
    color: #3a3a3a;
}

.chat_list__item__last_activity{
    position: relative;
    display: block;
    color: #666;
    font-size: .8rem;
}

.chat_list__item__timestamp{
    @extend .timestamp;
}