.message{
    display: flex;
    flex-direction: row;

    .message_inner{
        display: flex;
        flex-direction: row;
        margin: $margin-medium;
        animation: bubbleAppear 0.9s ease;
    }

    .message__text{
        text-align: left;
        padding: 13px;
        white-space: pre-line;
    }
}

.message_inner__content{
    display: flex;
    flex-direction: column;
    align-content: center;
}

.message__buble{
    display: inline-block;
    width: fit-content;
    height: fit-content;
    max-width: 400px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,.08);
    font-size: .8rem;
    color: #000;
    margin-bottom: .2rem;
    color: #2a2a2a;
    cursor: default;
    overflow: hidden;
    word-break: break-all;
}

@keyframes bubbleAppear{
    from{
        opacity: 0;
        transform: scale(0.95);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

.message__timestamp{
    font-size: .8rem;
    color: #8a8a8a;
}

.message.user{
    justify-content: flex-end;

    .message_inner{
        text-align: right;
        flex-direction: row-reverse;
    }

    .message_inner__content{
        align-items: flex-end;
    }
}