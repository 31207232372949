.chat_top_bar{
    display: flex;
    background: white;
    border-bottom: 1px solid $border-color-light;
    padding: 0 $padding-medium;
    min-height: 60px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.chat_top_bar__username{
    display: flex;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    align-items: center;
    margin-bottom: 0.3rem;

    .name{
        max-width: 300px;
    }
}

.chat_top_bar__created{
    font-size: .8rem;
    line-height: .8rem;
    color: $grey;
}