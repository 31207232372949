.kbs_list{
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.kbs_list_item{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
    padding: $padding-large $padding-medium;
    border-radius: $border-radius-small;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    height: 150px;

    .timestamp{
        display: flex;
        justify-content: flex-end;
    }

    &:hover{
        background-color: darken(white, 2%);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }
}

.kbs_list_item__name{
    font-weight: 300;
    color: $primary-color;
    font-size: 1.2rem;
}