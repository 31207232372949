.badge{
    padding: 0.5rem $padding-medium;
    background-color: $light-grey;
    color: $charcoal;
    width: fit-content;
    height: fit-content;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: $border-radius-small;
    cursor: default;
}