.chats_sidebar{
    position: relative;
    display: flex;
    background-color: white;
    width:  350px;
    transition: width 0.3s;
    border-left: 1px solid $border-color-light;
    justify-content: space-evenly;
    overflow-y: auto;
}

.chats_sidebar_inner{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.chats_sidebar_widgets{
    padding: $padding-medium;
}