.dashboard_menu{
    position: relative;
    display: flex;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    z-index: 222;
    padding: 0.5rem 0.8rem;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    align-items: center;
}

.dashboard_menu__logo{
    position: relative;
    height: 35px;
    margin-right: 1rem;
}


.dashboard_menu__inner_top, .dashboard_menu__inner_bottom{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboard_menu__inner_top{
    flex-grow: 1;
}

.dashboard_menu__item, .dashboard_menu__item_logout{
    position: relative;
    color: $charcoal;
    display: flex;
    font-weight: 500;
    font-family: inherit !important;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;

    svg{
        font-size: 30px;
        line-height: 30px;
        margin-right: 0.3rem;
    }

    &:last-child{
        margin-right: 0;
    }

    &:focus{
        outline: none;
    }

    &:hover{
        svg{
            color: $charcoal;
        }
    }
}

.dashboard_menu__item.active{
    font-weight: 500;
    svg{
        color: $charcoal;
    }
}