.preloader{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    top: 0;
}

.preloader_text{
    margin-top: 0.5rem;
    display: block;
    color: $charcoal;
}

.text_preloader{
    @extend .animated-background;
    color: transparent;
}

.modal__preloader{
    text-align: center;
}