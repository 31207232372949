.chat_date_badge{
    position: relative;
    padding: 6px;
    font-size: .8rem;
    margin: 15px auto;
    max-width: 120px;
    background: #e4e4e4;
    font-weight: 700;
    color: #6a6a6a;
    text-align: center;
    border-radius: .5rem;
}