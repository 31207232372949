.kbs_breadcrumbs{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.kbs_breadcrumbs__item{
    display: flex;
    align-items: flex-end;
    margin-right: 1rem;
    &:last-child{
        margin-right: 0;
    }

    &.active{
        .kbs_breadcrumbs__item__link{
            font-weight: 700;
        }
    }
}

.kbs_breadcrumbs__item_seperator{
    display: flex;
    align-items: center;
    margin-right: 1rem;
    line-height: 1.3rem;
}

.kbs_breadcrumbs__item__link{
    color: $primary-color;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
    cursor: pointer;
    max-width: 250px;
}

.kbs_breadcrumbs__item__back{
    @extend .back;
    font-size: 1rem;
    align-items: flex-end;
    display: flex;
    margin-right: 0;
    cursor: pointer;
}