.notifications{
    position: relative;
    display: block;
}

.notifications_trigger{
    position: relative;
    display: flex;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    cursor: pointer;
    transition: all 0.3s;
    color: $charcoal;

    &:hover{
        background-color: $offwhite;
    }
}

.notifications_container{
    position: absolute;
    right: 0;
    width: 350px;
    top: 100%;
    margin-top: 0.3rem;
    background: white;
    border-radius: $border-radius-small;
    box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid $border-color-light;
    animation: fadeIn 0.3s;
}