
.widget{
    position: relative;
    display: block;
    border: 1px solid $border-color-light;
    border-radius: $border-radius-small;
    margin-bottom: 1rem;

    &:last-child{
        margin-bottom: 0px;
    }
}

.widget_title{
    padding: $padding-medium;
    padding-bottom: 0;
    font-weight: 500;
}

.widget_title__inner{
    font-size: 0.8rem;
    font-weight: 500;
    color: #777;
    margin-bottom: 0.5rem;
    text-align: center;
}

.widget_inner{
    padding: $padding-medium;
}