.avatar_actions{
    position: relative;
}

.avatar_actions__inner{
    display: flex;
}

.avatar_action{
    padding: $padding-small $padding-medium;
    font-size: 0.75rem;
    color: $grey;
    border: 1px solid $border-color;
    cursor: pointer;
    &:first-child{
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
    }

    &:last-child{
        border-left: 0px;
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
    }

    &:only-child{
        border-radius: $border-radius-small;
        border: 1px solid $border-color;
    }
}