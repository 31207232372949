button-base{
    outline: none;
    border: none;
    cursor: pointer;
    &:focus{
        outline: none;
    }

    &:disabled{
        cursor: default;
        opacity: 0.6;
        pointer-events: none;
    }
    
    &.button_fit{
        width: fit-content;
        padding: 0.65rem 1.5rem;
    }
}

.button{
    @extend button-base;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $primary-color;
    padding: 0.65rem 0.5rem;
    color: white;
    border-radius: $border-radius-small;
    transition: background 0.3s ease-in-out;
    justify-content: center;

    &:hover{
        background-color: darken($primary-color, 10%);
    }   

    svg{
        margin-left: 0.3rem;
    }

    &.cancel{
        background-color: $charcoal;
        &:hover{
            background-color: darken($charcoal, 10%);
        }
    }
}
